import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faTrash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import "./PineconeComponent.css"; // Importar el archivo CSS

const PineconeComponent = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hostpinecone, setHostpinecone] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const recordsPerPage = 5; // Registros por página

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://managercors.fenalcovalle.com/https://asertisia.fenalcovalle.com/ia-fenalco/asertis/get-registers-pinecone",
        {
          method: "GET",
          headers: {
            topk: "1000",
            hostpinecone: hostpinecone,
            apikey: "da89ca1b-6f19-4a9f-a9f6-81f014f65a01",
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          "No se pudieron obtener los registros, valida el nombre del modelo"
        );
      }

      const data = await response.json();
      setRecords(data.all_sources);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (record) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          "https://managercors.fenalcovalle.com/https://asertisia.fenalcovalle.com/ia-fenalco/asertis/delete-registers-pinecone",
          {
            method: "DELETE",
            headers: {
              topk: "1000",
              hostpinecone: hostpinecone,
              source: record,
              apikey: "da89ca1b-6f19-4a9f-a9f6-81f014f65a01",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Hubo un problema al eliminar el registro");
        }

        setRecords(records.filter((r) => r !== record));
        Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
      } catch (err) {
        setError(err.message);
        Swal.fire(
          "Error!",
          "Hubo un problema al eliminar el registro.",
          "error"
        );
      }
    }
  };

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  // Paginación de los registros
  const displayedRecords = records.slice(
    pageNumber * recordsPerPage,
    (pageNumber + 1) * recordsPerPage
  );

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Registros Almacenados en BD Vectorial</h1>
      <div className="mb-3">
        <input
          type="text"
          value={hostpinecone}
          onChange={(e) => setHostpinecone(e.target.value)}
          className="form-control"
          placeholder="Ingrese el nombre del modelo"
        />
      </div>
      <button onClick={fetchRecords} className="btn btn-primary custom-button">
        {loading ? (
          <>
            <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
            Cargando...
          </>
        ) : (
          "Consultar Registros de BD"
        )}
      </button>
      {error && (
        <div className="alert alert-danger mt-3">{`Error: ${error}`}</div>
      )}
      <ul className="list-group mt-4">
        {displayedRecords.map((record, index) => (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between align-items-center custom-list-item"
          >
            <span>
              <FontAwesomeIcon icon={faFilePdf} className="me-2 pdf-icon" />
              {record}
            </span>
            <FontAwesomeIcon
              icon={faTrash}
              className="text-danger trash-icon me-2"
              onClick={() => handleDelete(record)}
            />
          </li>
        ))}
      </ul>

      {/* Paginación */}
      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        breakLabel={"..."}
        pageCount={Math.ceil(records.length / recordsPerPage)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={10}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center mt-4"}
        pageClassName={"page-item custom-page-item"}
        pageLinkClassName={"page-link custom-page-link"}
        previousClassName={"page-item custom-page-item"}
        previousLinkClassName={"page-link custom-page-link"}
        nextClassName={"page-item custom-page-item"}
        nextLinkClassName={"page-link custom-page-link"}
        activeClassName={"active custom-active"}
      />
    </div>
  );
};

export default PineconeComponent;
