// RecordsCalls.js
import React from 'react';
import './RecordsCalls.css'; // Asegúrate de importar el archivo CSS

const RecordsCalls = ({ data }) => {
  // Parse stringified JSON if necessary
  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch (e) {
      // If parsing fails, it means data is a simple string, so we continue
    }
  }

  // Check if the data is an array
  if (Array.isArray(data)) {
    return (
      <table className="records-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Evento</th>
            <th>De</th>
            <th>A</th>
            <th>Tipo de llamada</th>
            <th>Estado</th>
            {/* Agrega más encabezados según lo que necesites */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.registro_logs.event || 'N/A'}</td>
              <td>{item.registro_logs.call?.from_number || 'N/A'}</td>
              <td>{item.registro_logs.call?.to_number || 'N/A'}</td>
              <td>{item.registro_logs.call?.call_type || 'N/A'}</td>
              <td>{item.registro_logs.call?.call_status || 'N/A'}</td>
              {/* Agrega más celdas según los datos que quieras mostrar */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <span className="primitive-value">{String(data)}</span>;
  }
};

export default RecordsCalls;
