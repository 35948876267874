// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./components/auth/firebaseConfig";
import ChatComponent from "./components/chatComponentCopilot/ChatComponent";
import UploadFilePDF from "./components/uploadpdfvectorial/uploadfilepdf";
import RecordsCalls from "./components/recordsCalls/recordsCalls"; // Importa el componente correctamente
import PineconeComponent from "./components/pinecone/allpinecone";
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Header from "./Header";
import TypingText from "./TypingText";
import "./App.css";

const data = {
  jsonrpc: "2.0",
  id: null,
  result: {
    success: true,
    message: "Llamadas procesadas correctamente.",
    llamadas: [
      {
        id: 1,
        registro_logs: "{'registro_logs': 'adasd'}",
      },
      {
        id: 2,
        registro_logs: {
          event: "call_ended",
          call: {
            call_type: "phone_call",
            from_number: "+12137771234",
            to_number: "+12137771235",
            direction: "inbound",
            call_id: "Jabr9TXYYJHfvl6Syypi88rdAHYHmcq6",
            agent_id: "oBeDLoLOeuAbiuaMFXRtDOLriTJ5tSxD",
            call_status: "registered",
            metadata: {},
            retell_llm_dynamic_variables: {
              customer_name: "John Doe",
            },
            start_timestamp: 1714608475945,
            end_timestamp: 1714608491736,
            disconnection_reason: "user_hangup",
            transcript: "...",
            transcript_object: [{}, {}, {}, {}],
            transcript_with_tool_calls: [{}, {}, {}, {}],
            opt_out_sensitive_data_storage: false,
          },
        },
      },
      {
        id: 3,
        registro_logs: {
          event: "call_ended",
          call: {
            call_type: "phone_call",
            from_number: "+12137771234",
            to_number: "+12137771235",
            direction: "inbound",
            call_id: "Jabr9TXYYJHfvl6Syypi88rdAHYHmcq6",
            agent_id: "oBeDLoLOeuAbiuaMFXRtDOLriTJ5tSxD",
            call_status: "registered",
            metadata: {},
            retell_llm_dynamic_variables: {
              customer_name: "John Doe",
            },
            start_timestamp: 1714608475945,
            end_timestamp: 1714608491736,
            disconnection_reason: "user_hangup",
            transcript: "...",
            transcript_object: [{}, {}, {}, {}],
            transcript_with_tool_calls: [{}, {}, {}, {}],
            opt_out_sensitive_data_storage: false,
          },
        },
      },
    ],
  },
};

const App = () => {
  useEffect(() => {
    // ... (tu código de useEffect)
  }, []);

  return (
    <Router>
      <div className="appContainer">
        <Header />
        <div className="typing-text-container">
          <TypingText
            text={`Biienvenido a Asertis BPO-AI, estoy aquí para facilitar la optimización y el éxito en la gestión de operaciones.`}
            typingSpeed={40}
          />
        </div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/fundacionwwb"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/expomotor"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/asertisbpoia"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/comfevalle"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-pdf"
            element={
              <ProtectedRoute>
                <UploadFilePDF />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recordsCalls"
            element={
              <ProtectedRoute>
                <RecordsCalls data={data} /> {/* Pasa data como prop */}
              </ProtectedRoute>
            }
          />
          <Route
            path="/bdvectorial"
            element={
              <ProtectedRoute>
                <PineconeComponent />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
